html, body {
  padding: 0;
  margin: 0;
}

body {
  width: 100vw;
  height: 100vh;
}

* {
  box-sizing: border-box;
  font-size: 14px;
  -webkit-tap-highlight-color: transparent;
}

:root {
  --border-radius: 10px;
}

svg-icon {
  display: flex;
  width: 12px;
  height: 12px;
  fill: var(--text-color);
}

img {
  object-fit: scale-down;
}

.table-row-h75{
  line-height: 1.2;
  padding: 16px 6px;
  height: 75px !important;
  max-height: 75px !important;
  min-height: 75px !important;
  border-bottom: 1px solid var(--divider-color);
}

.table-header-h75{
  line-height: 1.2;
  padding: 16px 6px;
  height: 75px !important;
  max-height: 75px !important;
  min-height: 75px !important;
  border-bottom: 2px solid var(--divider-color);
}

.vertical-divider-w5 {
  border-right: 5px solid var(--divider-color);
  border-radius: 5px;
}

img {
  -webkit-user-drag: none;
  user-select: none;
}
a {
  -webkit-user-drag: none;
  color: inherit;
}

.text-preline {
  white-space: pre-line;
}


.outer-custom-radio-container {
  background-color:var(--background-color);
  border-radius: 50%;
  display: flex;
  border: 2px solid var(--primary-color);
  justify-content: center;
  align-items: center;
}

.inner-custom-radio-container {
  background-color: var(--primary-color);
  border-radius: 50%;
}

/*fix table header*/
.fix-table-header {
  background: var(--background-color);
  position: sticky;
  top: -25px;
  z-index: 9;
}

.toast-container {
  position: fixed;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 60;
  opacity: 0.4;
  background: var(--background-color);
}

.loading-overlay img {
  opacity: 1;
  z-index: 61;
}

.overlay{
  background-color: #212331;
  opacity: 0.8;
  position: fixed;
  z-index: 41;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.shop-main {
  width: calc(100% - 320px);
  overflow-y: auto;
}

@media (max-width: 1200px) {
  .shop-main {
    width: 100%;
  }
}
