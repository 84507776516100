@import "./assets/package/css/font.css";
@import "./assets/package/css/color.css";
@import "./assets/package/css/global.css";
@import "./assets/package/css/layout.css";
@import "./assets/package/css/padding.css";
@import "./assets/package/css/margin.css";
@import "./assets/package/css/size.css";
@import "./assets/package/css/native.css";
@import "./assets/package/css/animation.css";







/* scroll bar */
::-webkit-scrollbar { width: 16px;}
::-webkit-scrollbar-track { border-radius: var(--border-radius); }
::-webkit-scrollbar-thumb { background: var(--scroll-color); border-radius: var(--border-radius); border: 4px solid var(--scroll-background); }
::-webkit-scrollbar-thumb:hover { background: var(--scroll-color-hover); }


/*premium*/
.triangleTop {
  width: 0;
  height: 0;
  border-top: 14px solid var(--primary-color);
  border-right: 14px solid transparent;
  border-bottom: 14px solid transparent;
}
.triangleBottom {
  width: 0;
  height: 0;
  border-top: 14px solid transparent;
  border-right: 14px solid transparent;
  border-bottom: 14px solid var(--primary-color);
}
