:root {
  --primary-color: #1d2242;
  --primary-color-alpha:#1d22425e;
  --primary-hover-color: #1d2242;
  --danger-color: #d73636;
  --danger-hover-color: #890c0c;

  --background-color: #ffffff;
  --background-hover-color: #dadada;
  --foreground-color: #b4b4b4;

  --text-color: #000000;

  --input-background-color: #343434;
  --input-text-color: #fff;

  --button-background-color: #343434;
  --button-background-hover-color: #242424;
  --button-text-color: #fff;

  --border-color: #4e4e4e;

  --black-color: #000;
  --white-color: #fff;
  --gray-color: #818181;
  --yellow-color: gold;
  --yellow-hover-color: #a89100;


  --divider-color: #f1f1f1;
  --success-color: #5f9001;

  --scroll-background: #f0f2f5;
  --scroll-color: #cacaca;
  --scroll-color-hover: #a8a8a8;

  --status-on-hold-background:  #2e6aff4d;
  --status-on-hold-color: #2e6aff;
  --status-in-progress-background: #55cd4a4d;
  --status-in-progress-color: #55cd4a;
  --status-solved-background: #3737374d;
  --status-solved-color: #373737;
}
