.fxRow { display: flex; flex-direction: row; }
.fxRowStart { justify-content: flex-start; }
.fxRowEnd { justify-content: flex-end; }
.fxRowCenter { justify-content: center !important; }
.fxRowSpaceBetween { justify-content: space-between; }

.fxColumn { display: flex; flex-direction: column; }
.fxColumnReverse { display: flex; flex-direction: column-reverse; }
.fxColumnStart { align-items: flex-start; }
.fxColumnEnd { align-items: flex-end; }
.fxColumnCenter { align-items: center; }

.verticalAlignCenter { vertical-align: middle;}

.fxWrap { flex-wrap: wrap; }

.positionSticky { position: sticky; }
.positionRelative { position: relative; }
.positionFixed { position: fixed; }
.positionAbsolute { position: absolute; }
.positionStatic { position: static; }

.top0 { top: 0; }
.top50p { top: 50%; }
.top100vh { top: 100vh; }
.top0Lock { top: 0 !important; }

.left0 { left: 0; }
.left50p { left: 50%; }
.left100p { left: 100%; }

.bottom0 { bottom: 0; }

.scrollX { overflow-x: auto; }
.scrollY { overflow-y: auto; }
.noScroll { overflow: hidden; }
.noScrollX { overflow-x: hidden; }
.noScrollY { overflow-y: hidden; }

.transformCenter { transform: translate(-50%, -50%); }

.h60vh { height: 60vh; }
.h80vh { height: 80vh; }
.h90vh { height: 90vh; }
.h95vh { height: 95vh; }
.h100vh { height: 100vh; }
.h16vh { height: 16vh;}
.h12vh { height: 12vh }


.h80p { height: 80% !important; }
.w80p { width: 80%; }
.w70p { width: 70%; }
.w60p { width: 60%; }
.w50p { width: 50%; }
.w40p { width: 40%; }
.w30p { width: 30%; }
.w25p { width: 25%; }
.w20p { width: 20%; }
.w15p { width: 15%; }
.w10p { width: 10%; }
.w8p { width: 8%; }
.w5p { width: 5%; }
.wFit { width: fit-content; }
.w100vw { width: 100vw; }

.zIndex100 { z-index: 100; }
.zIndex999 { z-index: 999; }
.zIndex40 { z-index: 40; }

.h-no-header { height: calc(100vh - 105px) !important; }
.margin-header { margin-top: 105px !important; }

.aspect-ration-square {
    aspect-ratio: 1 / 1;
}

.h0w0{
    height: 0 !important;
    width: 0 !important;
}

@media (min-width: 992px) {
    .lg-positionRelative { position: relative !important; }
    .lg-positionFixed { position: fixed !important; }
    .lg-positionAbsolute { position: absolute !important; }
    .lg-positionStatic { position: static !important; }
    .lg-h100vh { height: 100vh !important;}
    .lg-h100p { height: 100% !important;}
    .h-no-header { height: calc(100vh - 176px) !important; }
    .margin-header { margin-top: 176px !important; }
    .lg-h-no-header { height: calc(100vh - 176px) !important; }
}
@media (min-width: 1200px) {
    .xl-positionRelative { position: relative !important; }
    .xl-positionFixed { position: fixed !important; }
    .xl-positionAbsolute { position: absolute !important; }
    .xl-positionStatic { position: static !important; }
    .xl-h-no-header { height: calc(100vh - 176px) !important; }
}

@media (max-width: 767px) {
    .margin-header { margin-top: 36px !important; }
}
