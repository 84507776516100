.p2 { padding: 2px; }
.px2 { padding-left: 2px; padding-right: 2px; }
.py2 { padding-top: 2px; padding-bottom: 2px; }
.pl2 { padding-left: 2px; }
.pt2 { padding-top: 2px; }
.pr2 { padding-right: 2px; }
.pb2 { padding-bottom: 2px; }

.p4 { padding: 4px; }
.px4 { padding-left: 4px; padding-right: 4px; }
.py4 { padding-top: 4px; padding-bottom: 4px; }
.pl4 { padding-left: 4px; }
.pt4 { padding-top: 4px; }
.pr4 { padding-right: 4px; }
.pb4 { padding-bottom: 4px; }

.p6 { padding: 6px; }
.px6 { padding-left: 6px; padding-right: 6px; }
.py6 { padding-top: 6px; padding-bottom: 6px; }
.pl6 { padding-left: 6px; }
.pt6 { padding-top: 6px; }
.pr6 { padding-right: 6px; }
.pb6 { padding-bottom: 6px; }

.p12 { padding: 12px; }
.px12 { padding-left: 12px; padding-right: 12px; }
.py12 { padding-top: 12px; padding-bottom: 12px; }
.pl12 { padding-left: 12px; }
.pt12 { padding-top: 12px; }
.pr12 { padding-right: 12px; }
.pb12 { padding-bottom: 12px; }

.p20 { padding: 20px; }
.px20 { padding-left: 20px; padding-right: 20px; }
.py20 { padding-top: 20px; padding-bottom: 20px; }
.pl20 { padding-left: 20px; }
.pt20 { padding-top: 20px; }
.pr20 { padding-right: 20px; }
.pb20 { padding-bottom: 20px; }

.p24 { padding: 24px; }
.px24 { padding-left: 24px; padding-right: 24px; }
.py24 { padding-top: 24px; padding-bottom: 24px; }
.pl24 { padding-left: 24px; }
.pt24 { padding-top: 24px; }
.pr24 { padding-right: 24px; }
.pb24 { padding-bottom: 24px; }

.p36 { padding: 36px; }
.px36 { padding-left: 36px; padding-right: 36px; }
.px40{ padding-left: 40px; padding-right: 40px; }
.px50 { padding-left: 50px; padding-right: 50px; }
.py36 { padding-top: 36px; padding-bottom: 36px; }
.pl36 { padding-left: 36px; }
.pt36 { padding-top: 36px; }
.pr36 { padding-right: 36px; }
.pb36 { padding-bottom: 36px; }

.p64 { padding: 64px; }
.px64 { padding-left: 64px; padding-right: 64px; }
.py64 { padding-top: 64px; padding-bottom: 64px; }
.pl64 { padding-left: 64px; }
.pt64 { padding-top: 64px; }
.pr64 { padding-right: 64px; }
.pb64 { padding-bottom: 64px; }
