.m6 { margin: 6px; }
.mx6 { margin-left: 6px; margin-right: 6px; }
.my6 { margin-top: 6px; margin-bottom: 6px; }
.ml6 { margin-left: 6px; }
.mt6 { margin-top: 6px; }
.mr6 { margin-right: 6px; }
.mb6 { margin-bottom: 6px; }

.m12 { margin: 12px; }
.mx12 { margin-left: 12px; margin-right: 12px; }
.my12 { margin-top: 12px; margin-bottom: 12px; }
.ml12 { margin-left: 12px; }
.mt12 { margin-top: 12px; }
.mr12 { margin-right: 12px; }
.mb12 { margin-bottom: 12px; }

.m24 { margin: 24px; }
.mx24 { margin-left: 24px; margin-right: 24px; }
.my24 { margin-top: 24px; margin-bottom: 24px; }
.ml24 { margin-left: 24px; }
.mt24 { margin-top: 24px; }
.mr24 { margin-right: 24px; }
.mb24 { margin-bottom: 24px; }


.m36 { margin: 36px; }
.mx36 { margin-left: 36px; margin-right: 36px; }
.my36 { margin-top: 36px; margin-bottom: 36px; }
.ml36 { margin-left: 36px; }
.mt36 { margin-top: 36px; }
.mr36 { margin-right: 36px; }
.mb36 { margin-bottom: 36px; }


