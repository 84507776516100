.maxScreen { width: 100vw; height: 100vh; }
.maxWidth { width: 100vw; }
.maxHeight { height: 100vh; }

.fullScreen { width: 100%; height: 100%; }
.fullWidth { width: 100%; }
.fullHeight { height: 100%; }

.maxInScreen { max-width: 95vw; max-height: 95vw; }

.size400 { width: 400px; height: 400px; min-width: 400px; min-height: 400px; }
.size350 { width: 350px; height: 350px; min-width: 350px; min-height: 350px; }
.size300 { width: 300px; height: 300px; min-width: 300px; min-height: 300px; }
.size250 { width: 250px; height: 250px; min-width: 250px; min-height: 250px; }
.size200 { width: 200px; height: 200px; min-width: 200px; min-height: 200px; }
.size150 { width: 150px; height: 150px; min-width: 150px; min-height: 150px; }
.size100 { width: 100px; height: 100px; min-width: 100px; min-height: 100px; }
.size90 { width: 90px; height: 90px; min-width: 90px; min-height: 90px; }
.size80 { width: 80px; height: 80px; min-width: 80px; min-height: 80px; }
.size70 { width: 70px; height: 70px; min-width: 70px; min-height: 70px; }
.size60 { width: 60px; height: 60px; min-width: 60px; min-height: 60px; }
.size50 { width: 50px; height: 50px; min-width: 50px; min-height: 50px; }
.size40 { width: 40px; height: 40px; min-width: 40px; min-height: 40px; }
.size30 { width: 30px; height: 30px; min-width: 30px; min-height: 30px; }
.size24 { width: 24px; height: 24px; min-width: 24px; min-height: 24px; }
.size20 { width: 20px; height: 20px; min-width: 20px; min-height: 20px; }
.size18 { width: 18px; height: 18px; min-width: 18px; min-height: 18px; }
.size16 { width: 16px; height: 16px; min-width: 16px; min-height: 16px; }
.size14 { width: 14px; height: 14px; min-width: 14px; min-height: 14px; }
.size12 { width: 12px; height: 12px; min-width: 12px; min-height: 12px; }
.size10 { width: 10px; height: 10px; min-width: 10px; min-height: 10px; }

.w6 { width: 6px; min-width: 6px; max-width: 6px; }
.w10 { width: 10px; min-width: 10px; max-width: 10px; }
.w20 { width: 20px; min-width: 20px; max-width: 20px; }
.w26 { width: 26px; min-width: 26px; max-width: 26px; }
.w30 { width: 30px; min-width: 30px; max-width: 30px; }
.w40 { width: 40px; min-width: 40px; max-width: 40px; }
.w50 { width: 50px; min-width: 50px; max-width: 50px; }
.w60 { width: 60px; min-width: 60px; max-width: 60px; }
.w70 { width: 70px; min-width: 70px; max-width: 70px; }
.w80 { width: 80px; min-width: 80px; max-width: 80px; }
.w90 { width: 90px; min-width: 90px; max-width: 90px; }
.w100 { width: 100px; min-width: 100px; max-width: 100px; }
.w128 { width: 128px; min-width: 128px; max-width: 128px; }
.w110 { width: 110px; min-width: 110px; max-width: 110px; }
.w150 { width: 150px; min-width: 150px; max-width: 150px; }
.w160 { width: 160px; min-width: 160px; max-width: 160px; }
.w166 { width: 166px; min-width: 166px; max-width: 166px; }
.w200 { width: 200px; min-width: 200px; max-width: 200px; }
.w250 { width: 250px; min-width: 250px; max-width: 250px; }
.w300 { width: 300px; min-width: 300px; max-width: 300px; }
.w350 { width: 350px; min-width: 350px; max-width: 350px; }
.w400 { width: 400px; min-width: 400px; max-width: 400px; }
.w450 { width: 450px; min-width: 450px; max-width: 450px; }
.w500 { width: 500px; min-width: 500px; max-width: 500px; }
.w550 { width: 550px; min-width: 550px; max-width: 550px; }
.w600 { width: 600px; min-width: 600px; max-width: 100px; }

.w70vw {width: 70vw;}

.w10p { width: 10%; }
.w16p { width: 16.66666667%; }
.w20p { width: 20%; }
.w25p { width: 25%; }
.w30p { width: 30%; }
.w33p { width: 33.33333333%; }
.w40p { width: 40%; }
.w50p { width: 50%; }
.w60p { width: 60%; }
.w70p { width: 70%; }
.w80p { width: 80%; }
.w90p { width: 90%; }
.w100p { width: 100%; }

.h1 { height: 1px; }
.h10 { height: 10px; }
.h20 { height: 20px; }
.h30 { height: 30px; }
.h40 { height: 40px; }
.h50 { height: 50px; }
.h60 { height: 60px; }
.h70 { height: 70px; }
.h80 { height: 80px; }
.h90 { height: 90px; }
.h100 { height: 100px; }
.h150 { height: 150px; }
.h200 { height: 200px; }
.h250 { height: 250px; }
.h300 { height: 300px; }
.h350 { height: 350px; }
.h400 { height: 400px; }
.h450 { height: 450px; }
.h500 { height: 500px; }
.h550 { height: 550px; }
.h600 { height: 600px; }

.h40vh { height: 40vh; }

.maxHeight350 { max-height: 350px; }
.maxHeight305 { max-height: 305px; }
.maxHeight85vh { max-height: 85vh; }
