/*font*/


.fontBold { font-family: "font bold", sans-serif; font-weight: bold }
.fontSemiBold { font-family: "font semi bold", sans-serif; }
.fontLight { font-family: "font light", sans-serif; }
.fontMedium { font-family: "font medium", sans-serif; }
.fontThin { font-family: "font thin", sans-serif; }

.font8 { font-size: 8px; }
.font10 { font-size: 10px; }
.font12 { font-size: 12px; }
.font14 { font-size: 14px; }
.font15 { font-size: 15px; }
.font16 { font-size: 16px; }
.font18 { font-size: 18px; }
.font20 { font-size: 20px; }
.font24 { font-size: 24px; }
.font28 { font-size: 28px; }
.font32 { font-size: 32px; }
.font36 { font-size: 36px; }
.font40 { font-size: 40px; }
.font60 { font-size: 60px; }
